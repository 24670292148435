




























import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import Contact from '@/components/Contact/Contact.vue'
import Loader from '@/components/Global/Loader.vue'

import { FaqApi, FaqItemDto } from '@/api'

@Component({
  components: {
    Contact,
    Content,
    Toolbar,
    Loader
  },
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    }
  }
})
export default class Faq extends mixins(State) {
  public faqItems: FaqItemDto[] = [];

  mounted () {
    this.fetch()
  }

  async fetch () {
    this.state = this.states.LOADING
    const api = new FaqApi()
    try {
      const { data } = await api.faqGetFaqByTag(['dp-na inlog'])
      this.faqItems = data
      this.state = this.states.LOADED
    } catch (e) {
      this.state = this.states.ERROR
    }
  }
}
